<template>
  <el-card shadow="never">
    <div class="card-header">
      <el-page-header @back="goBack">
        <template #content>
          <span class="text-large font-600 mr-3"> 行程 </span>
        </template>
        <template #extra>
          <div class="flex items-center">
            <el-button @click="addCulture" type="primary">添加</el-button>
          </div>
        </template>
      </el-page-header>
    </div>
  </el-card>
  <el-card shadow="never" style="margin-top:20px">
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="id" width="55" />
      <el-table-column prop="title" label="标题" />
      <el-table-column prop="name" label="会员" width="100" >
        <template #default="scope">
          {{scope.row.mid?scope.row.name:'系统发布'}}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" width="100" >
        <template #default="scope">
          {{scope.row.type==1?'自由行':'组织安排'}}
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="发布时间" width="180" >
        <template #default="scope">
          {{scope.row.date}} {{scope.row.week}}{{scope.row.timeType==1 ? '上午':'下午'}} {{scope.row.startTime}}
        </template>
      </el-table-column>
      <el-table-column prop="content" label="内容" />
      <el-table-column prop="addTime" label="添加时间" width="160" />
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button size="small" @click="edit(scope.row.id)">编辑</el-button>
          <el-button
            size="small"
            type="danger"
            @click="del(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="float:right;margin:20px">
      <el-pagination
        v-model:currentPage="current_page"
        v-model:page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-card>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import api from '@/api/index.js'
export default defineComponent({
setup() {
  const searchData = ref({
    words:'',
    addtime:'',
    page:1,
  })
  const handleSelectionChange = () => {
    console.log('go back')
  }
  const tableData = ref([])
  const pageSize = ref(10)
  const total = ref(0)
  const current_page = ref(1)
  return {
    tableData,
    searchData,
    pageSize,
    total,
    current_page,
    handleSelectionChange
  };
},
methods: {
  goBack(){
    this.$router.go(-1)
  },
  edit(id){
    this.$router.push({path:'/culture/CultureDetail',query:{id:id}})
  },
  addCulture(){
    this.$router.push('/culture/CultureDetail')
  },
  handleCurrentChange(val){
    this.searchData.page = val
    this.getList()
  },
  del(id){
    let that = this
    api.cultureApi.del({id:id}).then(res => {
        if (res.code === 0) {
          ElMessage({
            message: res.message,
            type: 'success',
          })
          that.getList()
        }else{
          ElMessage.error(res.message)
        }
    }).catch((res) => {
        ElMessage.error(res.message);
    })
  },
  search(){
    this.searchData.page=1
    this.getList()
  },
  getList(){
    let that = this
    api.cultureApi.getList(this.searchData).then(res => {
        if (res.code === 0) {
          that.pageSize = res.data.per_page
          that.tableData = res.data.data
          that.total = res.data.total
          that.current_page = res.data.current_page
        }else{
          ElMessage.error(res.message)
        }
    }).catch((res) => {
        ElMessage.error(res.message);
    })
  },
  checkfeedback(id){
    let that = this
    api.feedbackApi.checkfeedback({id:id}).then(res => {
        if (res.code === 0) {
            ElMessage({
              message: res.message,
              type: 'success',
            })
            that.getList()
        }else{
          ElMessage.error(res.message)
        }
    }).catch((res) => {
        ElMessage.error(res.message);
    })
  },
  delfeedback(id){
    let that = this
    api.feedbackApi.delfeedback({id:id}).then(res => {
        if (res.code === 0) {
            ElMessage({
              message: res.message,
              type: 'success',
            })
            that.getList()
        }else{
          ElMessage.error(res.message)
        }
    }).catch((res) => {
        ElMessage.error(res.message);
    })
  },
},
mounted () {
  this.getList()
}
})
</script>
<style>

</style>